import {
  EffectRenderer,
  EffectTriggerEvent,
  EffectType,
} from '@hyperconnect/effects/dist/EffectRenderer';
import * as Sentry from '@sentry/nextjs';

import store from 'src/stores';
import { handleDecoUnsupportAtom } from 'src/stores/deco/atom';
import { DecoEffect, EffectRendererName } from 'src/types/Deco';
import { ControlledPromise } from 'src/utils/controlled-promise';
import getDeviceInfo from 'src/utils/device/info';
import MediaStreamPipe from 'src/utils/media/pipes/base';

class EffectRendererPipe extends MediaStreamPipe {
  // SSR 불가능해서 런타임에 생성
  private static effectRenderer: EffectRenderer | null = null;

  private static initPromise: ControlledPromise<boolean> | null = null;

  /**
   * 이펙트 적용/해제 관련 로직이 동시 호출되면 이펙트 라이브러리 내에서 충돌 발생
   * 따라서 해당 task queue로 관련된 로직이 순차적으로 실행하여 원하는 렌더링 결과 보장
   */
  private static effectQueue: Promise<any> = Promise.resolve();

  public static getIsInitialized() {
    return EffectRendererPipe.effectRenderer !== null;
  }

  public static async initialize() {
    if (EffectRendererPipe.initPromise) {
      return EffectRendererPipe.initPromise.promise;
    }

    EffectRendererPipe.initPromise = new ControlledPromise<boolean>();

    try {
      const effectRenderer = new EffectRenderer();

      const device = getDeviceInfo();

      const isInitSuccess = await effectRenderer.initialize('/mediapipe/tasksvision', {
        faceLandmarkModelPath: '/mediapipe/face_landmarker.task', // 얼굴 인식이 필요한 effect에서 사용
        segmentationModelPath: '/mediapipe/selfie_segmenter.tflite', // 배경 인식이 필요한 effect에서 사용
        ...(!device.isMobile && {
          gestureModelPath: '/mediapipe/gesture_recognizer.task', // 손 동작 인식이 필요한 effect에서 사용
        }),
      });

      if (isInitSuccess) {
        EffectRendererPipe.effectRenderer = effectRenderer;
      }
      EffectRendererPipe.initPromise.resolve(isInitSuccess);
    } catch (err) {
      EffectRendererPipe.initPromise.resolve(false);
    }
    return EffectRendererPipe.initPromise.promise;
  }

  public static removeAllEffects() {
    EffectRendererPipe.effectRenderer?.removeAll();
  }

  private static enqueueTask<T>(task: () => Promise<T>): Promise<T> {
    const taskPromise = EffectRendererPipe.effectQueue.then(task);
    EffectRendererPipe.effectQueue = taskPromise.catch(() => {});
    return taskPromise;
  }

  public static getEffectType = (effect: DecoEffect) => {
    if (!effect.attributes) return null;
    switch (effect.attributes.effectRenderer[0]) {
      case EffectRendererName.LUT_FILTER: {
        return EffectType.LUTFilter;
      }
      case EffectRendererName.LIQUIFY:
      case EffectRendererName.STRETCH: {
        return EffectType.FaceDistortion;
      }
      case EffectRendererName.SKIN_SMOOTH: {
        return EffectType.FaceRetouch;
      }
      case EffectRendererName.TWO_D_STICKER:
      case EffectRendererName.THREE_D_STICKER:
      case EffectRendererName.MASK: {
        return EffectType.LegacyHeadgear;
      }
      case EffectRendererName.BACKGROUND:
      case EffectRendererName.BACKGROUND_BLUR: {
        return EffectType.BackgroundEffect;
      }
      case EffectRendererName.GESTURE: {
        return EffectType.Gesture;
      }
      default:
        return null;
    }
  };

  public static async removeEffect(effect: DecoEffect) {
    const effectType = EffectRendererPipe.getEffectType(effect);
    if (effectType === null) return;

    return EffectRendererPipe.enqueueTask(async () => {
      EffectRendererPipe.effectRenderer?.removeEffect(effectType, effect.effectId);
    });
  }

  public static async setEffect(
    effect: DecoEffect,
    resourceData: ArrayBuffer | null,
    onTrigger?: (event: EffectTriggerEvent) => void
  ) {
    return EffectRendererPipe.enqueueTask(async () => {
      const effectType = EffectRendererPipe.getEffectType(effect);

      /**
       * 이펙트 라이브러리를 키지 않은 상태에서 이펙트 제거 요청이 들어오면 아무것도 하지 않아도 됨
       */
      if (effectType === null && EffectRendererPipe.initPromise === null) {
        return true;
      }
      const isInitSuccess = await EffectRendererPipe.initialize();
      if (!isInitSuccess || !EffectRendererPipe.effectRenderer) return false;

      if (effectType === null) {
        EffectRendererPipe.removeAllEffects();
        return true;
      }

      try {
        const result = await EffectRendererPipe.effectRenderer.setEffect(
          effectType as any,
          resourceData,
          effect.effectId,
          onTrigger
        );

        if (!result) return false;

        switch (effectType) {
          case EffectType.FaceRetouch:
            EffectRendererPipe.effectRenderer.setEffectStrength(effectType, 1.0);
            break;
          case EffectType.BackgroundEffect:
            EffectRendererPipe.effectRenderer.setEffectStrength(effectType, 1.0);
            break;
        }

        return true;
      } catch (error) {
        Sentry.captureException(error);
        return false;
      }
    });
  }

  public async renderVideo() {
    const inputVideoElement = this.videoEl;
    const outputCanvasElement = this.canvasEl;

    if (!outputCanvasElement || !inputVideoElement) {
      return;
    }

    this.render = async () => {
      if (
        !inputVideoElement ||
        !outputCanvasElement ||
        inputVideoElement.readyState !== HTMLMediaElement.HAVE_ENOUGH_DATA
      ) {
        return;
      }

      if (EffectRendererPipe.initPromise?.ended && EffectRendererPipe.effectRenderer) {
        const { videoWidth, videoHeight } = inputVideoElement;
        outputCanvasElement.width = videoWidth;
        outputCanvasElement.height = videoHeight;
        try {
          EffectRendererPipe.effectRenderer.render(inputVideoElement, outputCanvasElement);
        } catch (err) {
          Sentry.captureException(err);
          store.set(handleDecoUnsupportAtom);
        }
      } else {
        this.drawOriginalVideo();
      }
    };
  }

  public dispose(): void {
    EffectRendererPipe.removeAllEffects();
    super.dispose();
  }
}

export default EffectRendererPipe;
