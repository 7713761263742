import { FC } from 'react';
import { useSetAtom } from 'jotai';
import { tryLoginTypeAtom } from 'src/stores/auth/atoms';
import { LoginModalType, loginModalTypeAtom } from 'src/stores/register/atoms';
import { LoginType } from 'src/types/register';
interface Props {
  render: FC<{
    onClick: () => void;
  }>;
}
export default function LoginPhone({
  render
}: Props) {
  const setLoginModalType = useSetAtom(loginModalTypeAtom);
  const setTryLoginType = useSetAtom(tryLoginTypeAtom);
  const onClick = () => {
    setLoginModalType(LoginModalType.PHONE_NUMBER_LOGIN);
    setTryLoginType(LoginType.SMS);
  };
  return render({
    onClick
  });
}