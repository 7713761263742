import { FC } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useSetAtom } from 'jotai';
import useLogin from 'src/hooks/useLogin';
import { GetGoogleUserInfoAPI } from 'src/stores/auth/apis';
import { socialTokenAtom, tryLoginTypeAtom } from 'src/stores/auth/atoms';
import { LoginType } from 'src/types/register';
import { SSOSocialToken } from 'src/types/Token';
interface Props {
  render: FC<{
    onClick: () => void;
  }>;
}
export default function LoginGoogle({
  render
}: Props) {
  const {
    loginOAuth
  } = useLogin();
  const setTryLoginType = useSetAtom(tryLoginTypeAtom);
  const setSocialToken = useSetAtom(socialTokenAtom);
  const handleInfo = (loginType: LoginType, token: SSOSocialToken) => {
    setTryLoginType(loginType);
    setSocialToken(token);
    const {
      accessToken,
      id
    } = token;
    loginOAuth({
      accessToken,
      id
    });
  };
  const onClick = useGoogleLogin({
    // scope: 'https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.gender.read',
    scope: 'https://www.googleapis.com/auth/userinfo.profile',
    flow: 'auth-code',
    onSuccess: async ({
      code
    }) => {
      const [{
        data: {
          accessToken
        }
      }
      /*
      {
      data: {
        genders: [{ value: gender }] = [{ value: undefined }],
        birthdays: [{ date: birthday }] = [{ date: undefined }],
      },
      }
      */] = await GetGoogleUserInfoAPI(code);
      handleInfo(LoginType.GOOGLE, {
        accessToken
        /*
        birth: (birthday?.year && birthday?.month >= 0 && birthday?.day) ? new Date(
          birthday.year,
          birthday.month - 1,
          birthday.day,
        ) : undefined,
        gender: gender?.toUpperCase(),
        */
      });
    }
  });
  return render({
    onClick
  });
}