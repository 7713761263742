import * as Sentry from '@sentry/nextjs';
import { Client, IMessage, StompHeaders, StompSubscription } from '@stomp/stompjs';

import store from 'src/stores';
import { eventMutateAtom } from 'src/stores/event/atoms';
import { lastStompMessageAtom } from 'src/stores/stomp/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { MessageBrokerErrorCode } from 'src/types/MessageBroker';

interface MessageBrokerClientProps {
  brokerInfo: {
    brokerURL: string;
    connectHeaders: StompHeaders;
  };
  /**
   * 새로운 Broker 정보를 요청하는 함수
   */
  renewBrokerInfoFn: () => Promise<{
    brokerURL: string;
    connectHeaders: StompHeaders;
  } | null>;
}

interface SubscriptionInfo {
  /**
   * 채널 구독 당시의 정보
   * STOMP 재연결 후, 구독 상태를 복구하기 위한 용도
   */
  channelId: string;
  onMessage: (message: IMessage) => void;
  headers?: StompHeaders;

  /**
   * 실제 STOMP subscription 객체
   */
  stompSubscription: StompSubscription | null;

  /**
   * 수신한 메시지의 ID 목록
   * Queue 채널 재구독 시, 이미 수신한 메시지를 필터링하기 위한 용도
   */
  receivedMessageIds: string[];
}

export class MessageBrokerClient {
  private stomp: Client;

  private subscriptions: Record<string, SubscriptionInfo> = {};

  private socketConnectionCheckTimerId: ReturnType<typeof setInterval> | null = null;

  private socketConnectionCheckAttempt = 0;

  private get isStompUsable() {
    return this.stomp.webSocket?.readyState === WebSocket.OPEN;
  }

  private renewBrokerInfo: MessageBrokerClientProps['renewBrokerInfoFn'];

  private shouldRenewBrokerInfo = false;

  constructor(props: MessageBrokerClientProps) {
    this.renewBrokerInfo = props.renewBrokerInfoFn;

    const { brokerURL, connectHeaders } = props.brokerInfo;
    this.stomp = this.createStompClient(brokerURL, connectHeaders);
    this.stomp.activate();
  }

  private createStompClient(brokerURL: string, connectHeaders: StompHeaders) {
    const stomp = new Client({
      brokerURL,
      connectHeaders,

      // heartbeat*2 동안 메시지를 받지못하면 재연결하도록 설정
      discardWebsocketOnCommFailure: true,
      heartbeatIncoming: 3000,
      reconnectDelay: 1000,
    });

    stomp.beforeConnect = async () => {
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'stomp.beforeConnect',
          shouldRenewBrokerInfo: this.shouldRenewBrokerInfo,
        },
      });
      if (this.shouldRenewBrokerInfo) {
        this.shouldRenewBrokerInfo = false;

        const newBrokerInfo = await this.renewBrokerInfo();
        store.set(eventMutateAtom, {
          eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
          eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
          eventParams: {
            debug: 'stomp.beforeConnect renewBrokerInfo done',
            newBrokerInfo,
          },
        });
        if (newBrokerInfo) {
          this.stomp.brokerURL = newBrokerInfo.brokerURL;
          this.stomp.connectHeaders = newBrokerInfo.connectHeaders;
        }
      }
    };

    stomp.onConnect = (frame) => {
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'stomp.onConnect', frame },
      });
      this.restoreSubscription();
      this.startSocketConnectionCheck();
    };

    stomp.onStompError = (message) => {
      const {
        headers: { ['error-code']: errorCode },
      } = message;

      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'stomp.onStompError', message, errorCode },
      });

      switch (Number(errorCode)) {
        /*  stomp credential expire시 onStompError fire */
        case MessageBrokerErrorCode.LoginErrorCode:
        case MessageBrokerErrorCode.LoginExpiredErrorCode:
          this.shouldRenewBrokerInfo = true;
          break;
        /* TODO: 그 이외 에러에 대해서 어떻게 핸들링하는지 찾아야 함. */
        default:
          break;
      }
    };

    stomp.onDisconnect = (frame) => {
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'stomp.onDisconnect', frame },
      });
    };

    stomp.onWebSocketError = (event) => {
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'stomp.onWebSocketError', event },
      });
    };

    stomp.onWebSocketClose = (event) => {
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'stomp.onWebSocketClose', event, code: event.code },
      });
    };

    return stomp;
  }

  dispose() {
    try {
      this.stomp.deactivate();
      this.resetSocketConnectionCheck();
    } catch (_error) {
      // Discard Error
    }
  }

  async send(payload: { message: string; channelId: string; clientId: string }) {
    if (!this.isStompUsable) {
      Sentry.captureMessage('stomp send when not usable', {
        extra: {
          payload,
        },
      });
      return;
    }

    const { message, channelId, clientId } = payload;
    this.stomp.publish({
      destination: channelId,
      headers: { 'sender-id': clientId },
      body: message,
    });
  }

  unsubscribe(channelId: string) {
    if (this.subscriptions[channelId]) {
      if (this.isStompUsable) {
        this.subscriptions[channelId].stompSubscription?.unsubscribe();
      }
      delete this.subscriptions[channelId];
    }
  }

  async subscribe({
    channelId,
    onMessage,
    headers,
  }: {
    channelId: string;
    onMessage: (message: IMessage) => void;
    headers?: StompHeaders;
  }) {
    store.set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
      eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
      eventParams: {
        debug: 'stomp.subscribe called',
        headers,
        channelId,
        hasStomp: !!this.stomp,
        readyState: this.stomp.webSocket?.readyState,
        isOnline: window.navigator.onLine,
        lastStompMessage: store.get(lastStompMessageAtom),
      },
    });

    const subscriptionInfo: SubscriptionInfo = {
      channelId,
      onMessage,
      headers,
      stompSubscription: null,
      receivedMessageIds: [],
    };

    this.subscriptions[channelId] = subscriptionInfo;

    if (this.isStompUsable) {
      this.subscriptions[channelId].stompSubscription = this.stompSubscribe({
        channelId,
        onMessage,
        headers,
      });
    }

    return subscriptionInfo;
  }

  private stompSubscribe({
    channelId,
    onMessage,
    headers,
  }: {
    channelId: string;
    onMessage: (message: IMessage) => void;
    headers?: StompHeaders;
  }) {
    const handleStompMessage = (message: IMessage) => {
      // Queue 재구독 시점에, 이미 수신한 메시지를 필터링
      const messageId = message.headers['message-id'];
      const { receivedMessageIds } = this.subscriptions[channelId];
      if (receivedMessageIds.includes(messageId)) {
        return;
      }
      receivedMessageIds.push(messageId);

      // 실제 메시지 핸들러
      onMessage(message);
    };

    const subscription = this.stomp.subscribe(channelId, handleStompMessage, headers);
    store.set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
      eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
      eventParams: {
        debug: 'stomp.subscribe sent',
        headers,
        channelId,
      },
    });
    this.stomp.watchForReceipt('RECEIPT_ON_SUBSCRIBE', (frame) => {
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'stomp.watchForReceipt', frame, headers, channelId },
      });
    });

    return subscription;
  }

  private restoreSubscription() {
    /**
     * Broker는 Reconnect 하면 subscription을 잃는다.
     * 다시 connect 되었을 때 subscription을 복구해 주어야한다.
     * 따라서 Broker 의 Subscribe 는 유지한 채, stomp subscription 만 교체한다.
     */
    Object.keys(this.subscriptions).forEach(async (key) => {
      const sub = this.subscriptions[key];
      const stompSubscription = this.stompSubscribe(sub);
      sub.stompSubscription = stompSubscription;
    });
  }

  private startSocketConnectionCheck() {
    const check = () => {
      if (!this.socketConnectionCheckTimerId) return;

      if (this.stomp.webSocket?.readyState === WebSocket.OPEN) {
        this.resetSocketConnectionCheck();
        store.set(eventMutateAtom, {
          eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
          eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
          eventParams: { debug: 'stomp.onConnect check pass' },
        });
      } else {
        this.socketConnectionCheckAttempt += 1;
        if (this.socketConnectionCheckAttempt >= 5) {
          this.shouldRenewBrokerInfo = true;
          this.resetSocketConnectionCheck();

          store.set(eventMutateAtom, {
            eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
            eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
            eventParams: {
              debug: 'stomp.onConnect check timeout',
              attempt: this.socketConnectionCheckAttempt,
              readyState: this.stomp.webSocket?.readyState,
            },
          });
        }
      }
    };

    this.socketConnectionCheckTimerId = setInterval(check, 500);
  }

  private resetSocketConnectionCheck() {
    this.socketConnectionCheckAttempt = 0;
    if (this.socketConnectionCheckTimerId) {
      clearInterval(this.socketConnectionCheckTimerId);
    }
  }
}
