import { useEffect, useState } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { pushChannelNameAtom, stompBrokerInfoAtom } from 'src/stores/auth/atoms';
import {
  closeStompAtom,
  createStompAtom,
  handleStompMessageAtom,
  stompAtom,
} from 'src/stores/stomp/atoms';

export default function useStompSubscriber() {
  const stomp = useAtomValue(stompAtom);
  const createStomp = useSetAtom(createStompAtom);
  const pushChannelName = useAtomValue(pushChannelNameAtom);
  const handleStompMessage = useSetAtom(handleStompMessageAtom);
  const closeStomp = useSetAtom(closeStompAtom);
  const [isStompReady, setIsStompReady] = useState(false);
  const stompBrokerInfo = useAtomValue(stompBrokerInfoAtom);

  useEffect(() => {
    if (stompBrokerInfo) {
      createStomp(stompBrokerInfo);
    } else {
      closeStomp();
    }
  }, [stompBrokerInfo, createStomp, closeStomp]);

  useEffect(
    () => () => {
      closeStomp();
    },
    [closeStomp]
  );

  useEffect(() => {
    let isCancelled = false;
    if (pushChannelName && stomp) {
      stomp
        .subscribe({
          channelId: pushChannelName,
          onMessage: handleStompMessage,
        })
        .then(({ stompSubscription }) => {
          if (!stompSubscription) return;

          if (isCancelled) {
            stomp.unsubscribe(pushChannelName);
          } else {
            setIsStompReady(true);
          }
        });
      return () => {
        isCancelled = true;
        stomp.unsubscribe(pushChannelName);
        setIsStompReady(false);
      };
    }

    setIsStompReady(false);
  }, [pushChannelName, handleStompMessage, stomp]);

  return {
    isStompReady,
  };
}
