import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import BrandLoginButton, { LoginBrandName } from 'src/components/BrandLoginButton';
import RecentMatchCounter from 'src/components/Counter/RecentMatchCounter';
import Gap from 'src/components/Gap';
import LoginApple from 'src/components/LoginApple';
import LoginFacebook from 'src/components/LoginFacebook';
import LoginGoogle from 'src/components/LoginGoogle';
import LoginPhone from 'src/components/LoginPhone';
import TextLogoBase from 'src/components/Logo/TextLogo';
import ModalLayoutBase, { ModalHeader, MobileModalType, ModalLoading } from 'src/components/ModalLayout';
import useEvent from 'src/hooks/useEvent';
import { useGetConsents } from 'src/hooks/useGetConsents';
import useHelpCenterUrl from 'src/hooks/useHelpCenterUrl';
import useLogin from 'src/hooks/useLogin';
import { isLoginLoadingAtom } from 'src/stores/auth/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { EVENT_NAME, EVENT_TYPE, EventLoginModalVisitorTrigger } from 'src/types/Event';
import { LOGIN_METHODS } from 'src/types/Login';
import { ModalNavButtonType, ModalType } from 'src/types/Modal';
import { setTargetBlankToLinks } from 'src/utils/html';
const ModalLayout = styled(ModalLayoutBase)`
  width: 400px;
`;
const TextLogo = styled(TextLogoBase)`
  margin-bottom: 32px;
`;
const TermsOfUse = styled.span`
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  word-break: keep-all;
  color: ${({
  theme
}) => theme.color.content8};
  text-align: center;
  margin-bottom: 24px;

  a {
    color: ${({
  theme
}) => theme.color.content8};
    font-weight: 600;
  }
`;
const LoginModalCounter = styled(RecentMatchCounter)`
  color: ${({
  theme
}) => theme.color.content12};
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
`;
const LoginModalCounterDesc = styled.p`
  font-size: 15px;
  color: ${({
  theme
}) => theme.color.content12};
  margin-bottom: 40px;
  text-align: center;
  word-break: break-word;
`;
const Container = styled.div`
  flex-direction: column;
  display: flex;
  padding: 0 40px 40px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${({
  theme
}) => theme.screenSize.mobile} {
    width: 100%;
    height: calc(100% - 58px);
    padding: 0px 24px 16px 24px;
  }
`;
const TopContainer = styled.div`
  flex-direction: column;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  ${({
  theme
}) => theme.screenSize.mobile} {
    padding: 0 16px;
  }
`;
const Contact = styled.a`
  margin-top: 32px;
  margin-bottom: 12px;
  color: ${({
  theme
}) => theme.color.contentDeep};
  font-size: 14px;
`;
const DownloadContainer = styled.div`
  display: none;
  ${({
  theme
}) => theme.screenSize.mobile} {
    display: flex;
    flex-direction: column;
    color: ${({
  theme
}) => theme.color.content8};
    font-size: 12px;
    font-weight: 700;
    width: 100%;
    align-items: center;
  }
`;
const DownloadButtonContainer = styled.div`
  margin-top: 12px;
  display: flex;
  height: 29px;
`;
const DownloadButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 91px;
  height: 100%;
  background-color: ${({
  theme
}) => theme.color.surface4};
  border-radius: 100px;
  :active {
    background-color: ${({
  theme
}) => theme.color.surface5};
  }
`;
const DownloadIcon = ({
  theme
}: {
  theme: Theme;
}) => css`
  fill: ${theme.color.content12};
  width: 16px;
  height: 16px;
`;
const IcApple = styled(dynamic(() => import('src/assets/images/imgBrandApple.svg')))`
  ${DownloadIcon}
`;
const IcGooglePlay = styled(dynamic(() => import('src/assets/images/imgBrandGooglePlay.svg')))`
  ${DownloadIcon}
`;
export interface Props {
  loginSuccessCallback?: () => void;
  trigger?: EventLoginModalVisitorTrigger;
}
const LoginEntry: FC<Props> = ({
  loginSuccessCallback,
  trigger
}) => {
  const {
    t,
    i18n: {
      language
    }
  } = useTranslation();
  const {
    isLogin
  } = useLogin();
  const helpCenterURL = useHelpCenterUrl();
  const pushEvent = useEvent();
  const isLoginLoading = useAtomValue(isLoginLoadingAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const {
    isLoading: isConsentsLoading,
    data: consentsData
  } = useGetConsents();
  const {
    consentsRequired,
    uiType: consentsUiType
  } = consentsData?.data?.result || {};
  const termsOfUse = consentsRequired?.find(({
    type
  }) => type === 'TERM_OF_USE');
  useEffect(() => {
    if (isLogin) {
      if (loginSuccessCallback) {
        loginSuccessCallback();
      }
      closeModal(ModalType.LOGIN);
    }
  }, [isLogin, loginSuccessCallback, closeModal]);
  useEffect(() => {
    if (language && trigger) {
      pushEvent({
        eventType: EVENT_TYPE.SIGN_UP,
        eventName: EVENT_NAME.SCREEN_OPEN__LOGIN,
        eventParams: {
          language,
          trigger
        }
      });
    }
  }, [pushEvent, language, trigger]);
  return <ModalLayout isDim data-testid='login-modal' mobileType={MobileModalType.SCREEN_MODAL} closeWhenClickOutside={false} closeWithESC={false} navButtonType={ModalNavButtonType.CLOSE} data-sentry-element="ModalLayout" data-sentry-component="LoginEntry" data-sentry-source-file="LoginEntry.tsx">
      <ModalHeader data-sentry-element="ModalHeader" data-sentry-source-file="LoginEntry.tsx" />
      {(isLoginLoading || isConsentsLoading) && <ModalLoading type='absolute' />}
      <Container data-sentry-element="Container" data-sentry-source-file="LoginEntry.tsx">
        <TopContainer data-sentry-element="TopContainer" data-sentry-source-file="LoginEntry.tsx">
          <TextLogo width={137} height={36} arWidth={74} arHeight={56} ptWidth={175} ptHeight={36} data-sentry-element="TextLogo" data-sentry-source-file="LoginEntry.tsx" />
          <LoginModalCounter data-sentry-element="LoginModalCounter" data-sentry-source-file="LoginEntry.tsx" />
          <LoginModalCounterDesc data-sentry-element="LoginModalCounterDesc" data-sentry-source-file="LoginEntry.tsx">{t('online__matching_now')}</LoginModalCounterDesc>
          {consentsUiType === 'CONSENT_WEB_B' && termsOfUse && <TermsOfUse dangerouslySetInnerHTML={{
          __html: setTargetBlankToLinks(termsOfUse.agreementMessage + ' ' + termsOfUse.description)
        }} />}
          {LOGIN_METHODS.includes('google') && <LoginGoogle render={({
          onClick
        }) => <BrandLoginButton data-testid='Login-button-google' onClick={onClick} brand={LoginBrandName.GOOGLE} />} />}
          {LOGIN_METHODS.includes('facebook') && <LoginFacebook render={({
          onClick
        }) => <BrandLoginButton onClick={onClick} brand={LoginBrandName.FACEBOOK} />} />}
          {LOGIN_METHODS.includes('phone') && <LoginPhone render={({
          onClick
        }) => <BrandLoginButton data-testid='Login-button-phone-number' onClick={onClick} brand={LoginBrandName.PHONE} />} />}
          {LOGIN_METHODS.includes('apple') && <LoginApple render={({
          onClick
        }) => <BrandLoginButton onClick={onClick} brand={LoginBrandName.APPLE} />} />}
          <Contact href={helpCenterURL} data-sentry-element="Contact" data-sentry-source-file="LoginEntry.tsx">{t('LOGIN__CONTACT_US')}</Contact>
        </TopContainer>
        <DownloadContainer data-sentry-element="DownloadContainer" data-sentry-source-file="LoginEntry.tsx">
          {t('menu_download_app')}
          <DownloadButtonContainer data-sentry-element="DownloadButtonContainer" data-sentry-source-file="LoginEntry.tsx">
            <DownloadButton href='https://t64z.adj.st/discover?adj_t=t26roxr&adj_deep_link=azarlive%3A%2F%2Fdiscover&adj_campaign=mobile_login_modal' target='_blank' rel='noopener noreferrer' data-sentry-element="DownloadButton" data-sentry-source-file="LoginEntry.tsx">
              <IcApple data-sentry-element="IcApple" data-sentry-source-file="LoginEntry.tsx" />
            </DownloadButton>
            <Gap width={8} data-sentry-element="Gap" data-sentry-source-file="LoginEntry.tsx" />
            <DownloadButton href='https://t64z.adj.st/discover?adj_t=t26roxr&adj_deep_link=azarlive%3A%2F%2Fdiscover&adj_campaign=mobile_login_modal' target='_blank' rel='noopener noreferrer' data-sentry-element="DownloadButton" data-sentry-source-file="LoginEntry.tsx">
              <IcGooglePlay data-sentry-element="IcGooglePlay" data-sentry-source-file="LoginEntry.tsx" />
            </DownloadButton>
          </DownloadButtonContainer>
        </DownloadContainer>
      </Container>
    </ModalLayout>;
};
export default LoginEntry;