import { FC } from 'react';
import { useSetAtom } from 'jotai';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLoginBase from 'react-facebook-login/dist/facebook-login-render-props';
import useLogin from 'src/hooks/useLogin';
import { socialTokenAtom, tryLoginTypeAtom } from 'src/stores/auth/atoms';
import { facebookAppId } from 'src/types/OAuthToken';
import { LoginType } from 'src/types/register';
import { SSOSocialToken } from 'src/types/Token';
interface Props {
  render: FC<{
    onClick: () => void;
  }>;
}
export default function LoginFacebook({
  render
}: Props) {
  const {
    loginOAuth
  } = useLogin();
  const setTryLoginType = useSetAtom(tryLoginTypeAtom);
  const setSocialToken = useSetAtom(socialTokenAtom);
  const handleInfo = (loginType: LoginType, token: SSOSocialToken) => {
    setTryLoginType(loginType);
    setSocialToken(token);
    const {
      accessToken,
      id
    } = token;
    loginOAuth({
      accessToken,
      id
    });
  };
  const handleFBCallback = async (userInfo: ReactFacebookLoginInfo) => {
    const {
      accessToken,
      userID: id,
      email = ''
    } = userInfo;
    /*
    const {
      data: {
        birthday,
        gender,
      },
    } = await GetFacebookUserInfoAPI(accessToken);
    */
    handleInfo(LoginType.FACEBOOK, {
      accessToken,
      id,
      email
      // birth: birthday ? new Date(birthday) : undefined,
      // gender: gender?.toUpperCase(),
    });
  };
  const FacebookLogin = FacebookLoginBase as any;
  return <FacebookLogin isMobile={false} appId={facebookAppId} callback={handleFBCallback}
  // scope='public_profile, email, user_birthday, user_gender'
  scope='public_profile, email' render={({
    onClick
  }: any) => render({
    onClick
  })} data-sentry-element="FacebookLogin" data-sentry-component="LoginFacebook" data-sentry-source-file="index.tsx" />;
}