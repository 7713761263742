import type { MouseEventHandler, ReactElement } from 'react';
import { useSetAtom } from 'jotai';
import jwtDecode from 'jwt-decode';
import AppleLogin, { AppleJWT, AppleLoginError, AppleLoginResponse } from 'react-apple-login';
import useLogin from 'src/hooks/useLogin';
import { socialTokenAtom, tryLoginTypeAtom } from 'src/stores/auth/atoms';
import { showToastAtom } from 'src/stores/toast/atoms';
import { appleAppId, appleRedirectUri } from 'src/types/OAuthToken';
import { LoginType } from 'src/types/register';
import { ToastType } from 'src/types/Toast';
import { SSOSocialToken } from 'src/types/Token';
interface Props {
  /** TODO: onClick 은 그냥 () => void 면 되는데 react-apple-login 에서 쓸데없이 강타입을 걸어놔서 타입 에러가 발생함
   *  어차피 react-apple-login 은 unmaintained library 이니 없애버리고 로직을 직접 짜도록 바꾸자.
   */
  render: (props: {
    onClick: MouseEventHandler<HTMLDivElement>;
  }) => ReactElement;
}
export default function LoginApple({
  render
}: Props) {
  const {
    loginOAuth
  } = useLogin();
  const setTryLoginType = useSetAtom(tryLoginTypeAtom);
  const setSocialToken = useSetAtom(socialTokenAtom);
  const showToast = useSetAtom(showToastAtom);
  const handleInfo = (loginType: LoginType, token: SSOSocialToken) => {
    setTryLoginType(loginType);
    setSocialToken(token);
    const {
      accessToken,
      id
    } = token;
    loginOAuth({
      accessToken,
      id
    });
  };
  const handleAppleCallback = (res: AppleLoginError | AppleLoginResponse) => {
    if ('error' in res) {
      if (res.error.error !== 'popup_closed_by_user') {
        showToast({
          type: ToastType.ERROR,
          message: 'LOGIN__ERROR'
        });
      }
      return;
    }
    const idToken: AppleJWT = jwtDecode(res.authorization.id_token);
    handleInfo(LoginType.APPLE, {
      accessToken: res.authorization.id_token,
      id: idToken.sub,
      email: res.user?.email || idToken.email,
      appleAccountName: ''
    });
  };
  return <AppleLogin clientId={appleAppId} redirectURI={appleRedirectUri} usePopup={true} render={({
    onClick
  }) => render({
    onClick
  })} callback={handleAppleCallback} scope='email' responseMode='form_post' data-sentry-element="AppleLogin" data-sentry-component="LoginApple" data-sentry-source-file="index.tsx" />;
}